<template>
  <div>
    <b-modal
      ref="modalDataMediacao"
      :title="$t('Redefinir contrato')"
      :cancel-title="$t('Fechar')"
      cancel-variant="outline-secondary"
      size="lg"
      body-class=""
      :visible="false"
      :ok-only="showButtonNext === 1 ? false : true"
      :ok-title="showButtonNext === 1 ? formModeView === true ? $t('Guardar alteração') : $t('Seguinte') : $t('Fechar')"
      ok-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @ok="showButtonNext === 1 ? formModeView === true ? confirmSaveModal($event) : confirmNextModal($event) : confirmSaveModal($event)"
      @cancel="closeModal"
    >
      <b-row>
        <b-col md="5">
          <b-form-group
            :label="$t('Data de início')"
          >
            <b-input-group>
              <date-picker
                v-model="dtaStart"
                value-type="format"
                :input-class="{'form-control' : true }"
                type="date"
                :editable="false"
                :clearable="true"
                :lang="langCalendar"
                :append-to-body="true"
                prefix-class="sw"
                :disabled-date="disabledMinDate"
                @input="getDateEnd"
              >
                <template #icon-clear>
                  <v-select-deselect />
                </template>
              </date-picker>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            :label="$t('Meses')"
          >
            <v-select
              v-model="months"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="['ES, AR'].includes(iso) ? load_data_geral.arrayMesesOutro : load_data_geral.arrayMeses"
              :value="months"
              append-to-body
              :calculate-position="withPopper"
              label="desc"
              item-text="desc"
              item-value="id"
              @input="getDateEnd"
            >
              <template #option="{ desc }">
                {{ desc }}
              </template>
              <div slot="no-options">
                {{ $t('Sem resultados') }}
              </div>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col md="5">
          <b-form-group
            :label="$t('Data de fim')"
          >
            <b-form-input
              v-model="dtaEnd"
              :disabled="['ES, AR'].includes(iso) && true && (typeof months === 'undefined' || months === null || Number(months.id) === 0) ? false : true"
              :options="maskDate"
              placeholder="YYYY-MM-DD"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>
      </b-row>

    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BFormInput, BInputGroup,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useAppConfig from '@core/app-config/useAppConfig'
import { mixFormGeral } from '../../mixins'

const oDatepicker = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ 'vue2-datepicker'),
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ '@/assets/scss/datepicker.scss'),
]).then(([DatePicker]) => DatePicker)

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    vSelect,
    vSelectDeselect: vSelect.components.Deselect,
    DatePicker: oDatepicker,
  },
  directives: {
    Ripple,
  },
  mixins: [mixFormGeral],
  props: {
    showButtonNext: {
      type: Number,
      default: () => 0,
    },
    dataStart: {
      type: String,
      default: () => '',
    },
    dataMonths: {
      type: Object,
      default: () => {},
    },
    dataEnd: {
      type: String,
      default: () => '',
    },
    iso: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      dtaStart: this.dataStart,
      dtaEnd: this.dataEnd,
      months: this.dataMonths,
      maskDate: {
        date: true,
        delimiter: '-',
        datePattern: ['Y', 'm', 'd'],
      },
      langCalendar: {},
    }
  },
  computed: {
    ...mapGetters('listings_form', ['form_data', 'formModeView', 'load_data_geral']),
    isDark() {
      return this.skin.value === 'dark'
    },
  },
  async mounted() {
    await this.importLocale(this.$i18n.locale).then(res => {
      this.langCalendar = res.default
      // Forçei estes valores para a semana começar sempre no domingo
      this.langCalendar.formatLocale.firstDayOfWeek = 0
      this.langCalendar.formatLocale.firstWeekContainsDate = 4
    })
  },
  methods: {
    showModal() {
      return new Promise((resolve, reject) => {
        this.$refs.modalDataMediacao.show()
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    hideModal() {
      this.$refs.modalDataMediacao.hide()
    },
    async confirmNextModal(evt) {
      evt.preventDefault()
      this.resolvePromise(true)
    },
    async confirmSaveModal(evt) {
      evt.preventDefault()
      if (this.showButtonNext === 0 && this.formModeView === false) {
        if (this.dtaStart !== '' && this.dtaEnd !== '' && this.months !== '') {
          if (Date.parse(this.dtaEnd) < Date.parse(this.dtaStart)) {
            this.dtaEnd = ''
            await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s18', value: null })
            this.showMsgErrorRequest(new Error(this.$t('Preencha a data de fim de contrato')))
          } else {
            this.resolvePromise(true)
          }
        } else {
          this.showMsgErrorRequest(new Error(this.$t('Preencha as datas')))
        }
      } else {
        this.resolvePromise(true)
      }
    },
    async closeModal() {
      this.resolvePromise(false)
    },
    async getDateEnd() {
      const vlDateHome = this.dtaStart
      const vlMes = (typeof this.months !== 'undefined' && this.months !== null && typeof this.months.id !== 'undefined' ? this.months.id : '')
      const vlData = this.dtaStart

      this.$root.$emit('app::loading', true)

      await this.$store.dispatch('listings_form/checkDatesCMI_EstadoCancelado', { dataHome: vlData, numbMonth: vlMes }).then(async response => {
        if (response.data.error === 1) {
          this.$root.$emit('app::loading', false)

          this.showMsgErrorRequest(new Error(response.data.msg))

          this.dtaStart = response.data.date_cmi_home
          this.dtaEnd = response.data.date_cmi_end
          this.months = this.load_data_geral.arrayMeses.find(o => Number(o.id) === Number(response.data.date_cmi_month))

          // Atualiza os valores da popup no formulario, devido aos campos estarem bloqueados
          if (this.formModeView === false) {
            await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s17', value: this.dtaStart })
            await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s18', value: this.dtaEnd })
            await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s68', value: this.months })
            await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s329', value: null })
          }
        } else {
          await this.$store.dispatch('listings_form/addMonthDate', { dataHome: vlData, month: vlMes, fReturn: 1 }).then(async resp => {
            this.$root.$emit('app::loading', false)

            this.dtaEnd = resp.data.date

            if ((['ES', 'AR'].includes(this.iso)) && vlMes === 0) {
              // Define a data final como o limite da data de inicio
              if (vlDateHome) {
                this.dtaEnd = vlDateHome
              }
            }

            // Atualiza os valores da popup no formulario, devido aos campos estarem bloqueados
            if (this.formModeView === false) {
              await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s17', value: this.dtaStart })
              await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s18', value: this.dtaEnd })
              await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s68', value: this.months })
              await this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s329', value: null })
            }
          }).catch(error => {
            this.showMsgErrorRequest(error)
          })
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async importLocale(lang) {
      return import(`vue2-datepicker/locale/${lang}`)
    },
    disabledMinDate(date) {
      if (this.load_data_geral.limitContratoMediacao === true) {
        const now = new Date()
        const maxDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        maxDate.setDate(maxDate.getDate() - 90)
        return date < maxDate
      }

      return false
    },
  },
  setup() {
    const { skin } = useAppConfig()

    return {
      skin,
    }
  },
}
</script>
